'use client'

import { PropsWithChildren, createContext, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getSstStageAction } from '@/app/lib/actions/sstStage'

const LOCAL_STORAGE_STAGE_KEY = 'BENIQ_SST_STAGE_CACHE'

const saveSstStageToLocalStorage = (stage: string) => localStorage.setItem(LOCAL_STORAGE_STAGE_KEY, stage)

const getSstStageFromLocalStorage = () =>
  (process.env.SST_STAGE || localStorage.getItem(LOCAL_STORAGE_STAGE_KEY)) ?? undefined

const useSstStage = () => {
  if (typeof window === 'undefined') return process.env.SST_STAGE

  const { data: sstStage } = useQuery({
    queryFn: async () => {
      const stage = await getSstStageAction()
      saveSstStageToLocalStorage(stage)

      return stage
    },
    queryKey: ['stage'],
    initialData: getSstStageFromLocalStorage(),
  })

  return sstStage
}

const SstStageContext = createContext<ReturnType<typeof useSstStage> | null>(null)

const SstStageProvider = ({ children }: PropsWithChildren) => {
  const stage = useSstStage()

  return <SstStageContext.Provider value={stage}>{children}</SstStageContext.Provider>
}

const useSstStageContext = () => {
  const context = useContext(SstStageContext)

  if (!context) {
    throw new Error('useSstStageContext must be used within a SstStageProvider')
  }

  return context
}

export { useSstStageContext as useSstStage, SstStageProvider, getSstStageFromLocalStorage }
