import { PatientBIEnriched } from '@core/repository/patientBenefitInvestigation'
import { ClientSchema } from '@core/zod/prismaZodSchema'
import type { ClientProductGroup } from '@prisma/client'
import { z } from 'zod'

export function clientToOption(client: z.infer<typeof ClientSchema>, group: ClientProductGroup | null = null) {
  return {
    value: `${client.id}${group ? `-${group.id}` : ''}`,
    label: group ? group.name : client.companyName || client.type || '',
    data: {
      ...client,
      group,
    },
  }
}

export function investigationToClientWithGroup(investigation: PatientBIEnriched) {
  return {
    ...investigation.patient.client,
    group: investigation.product.clientProductGroup,
  }
}
