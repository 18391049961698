export function camelToTitleCase(str: string): string {
  return (
    str
      // Insert a space before any uppercase letter that's preceded by a lowercase letter
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // Capitalize the first letter of each word
      .replace(/\b\w/g, (match) => match.toUpperCase())
  )
}

/**
 * Format a string as a dollar amount with cents, supporting negative values.
 */
export function getFormattedMoney(value: string): string {
  if (value === '') {
    return ''
  }

  const isNegative = value.startsWith('-')

  // Remove irrelevant characters, preserving the decimal point
  const cleanValue = value.replace(/[^\d.]/g, '')

  // Split into dollars and cents
  const [dollars, centsRaw] = cleanValue.split('.')
  let cents = centsRaw

  // Check if has any value
  if ((dollars === '' && cents === '') || typeof dollars === 'undefined') {
    cents ||= '0'
  }

  // Format the dollars with commas
  const formattedDollars = dollars ? parseInt(dollars).toLocaleString() : ''

  // Handle cents
  let formattedCents = ''
  if (cents !== undefined) {
    // Limit cents to two decimal places
    formattedCents = '.' + cents.slice(0, 2)
  }

  // Combine and add minus sign if negative
  return `${isNegative ? '-' : ''}$${formattedDollars}${formattedCents}`
}

/**
 * Convert a money string to cents.
 */
export function moneyStringToCents(value: string): number | null {
  if (value === '') {
    return null
  }

  // Remove irrelevant characters
  const cleanValue = value.replace(/[^\d.]/g, '')

  const isNegative = value.startsWith('-')

  // Split into dollars and cents
  const [dollars, cents] = cleanValue.split('.')

  // Parse into numbers
  const dollarsNum = parseInt(dollars || '0')
  const centsNum = Math.min(parseInt(cents || '0'), 99)

  return (dollarsNum * 100 + centsNum) * (isNegative ? -1 : 1)
}

/**
 * Format cents as a dollar amount.
 */
export function centsToFormattedMoney(cents: number | null | undefined) {
  if (cents === null || cents === undefined) return null

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(cents / 100)
}

/**
 * Convert a camelCase or PascalCase string to snake_case,
 * making sure digits (like "riskIndicator1") become "risk_indicator_1".
 * Existing underscores remain intact (so "risk_indicator_1" stays unchanged).
 */
export function toSnakeCase(str: string): string {
  return (
    str
      // 1) Insert underscore between sequences of capital letters & followed by lower/number
      //    e.g. "HTMLGenerator" -> "HTML_Generator"
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1_$2')

      // 2) Insert underscore between a lower/number and an uppercase letter
      //    e.g. "someFieldName" -> "some_field_name"
      .replace(/([a-z\d])([A-Z])/g, '$1_$2')

      // 3) Insert underscore between a letter and a digit
      //    e.g. "riskIndicator1" -> "risk_indicator_1"
      .replace(/([A-Za-z])(\d+)/g, '$1_$2')

      // 4) Finally, lower-case everything
      .toLowerCase()
  )
}

export function toKebabCase(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1).replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
}
