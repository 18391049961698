import { z } from 'zod';

/////////////////////////////////////////
// CLIENT PRODUCT GROUP SCHEMA
/////////////////////////////////////////

export const ClientProductGroupSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  name: z.string(),
  clientId: z.string(),
})

export type ClientProductGroup = z.infer<typeof ClientProductGroupSchema>

export default ClientProductGroupSchema;
