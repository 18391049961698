'use client'

import { useMutation } from '@tanstack/react-query'
import { resetDemoDataAction } from '../../actions/resetDemoData'
import { toast } from '@/components/ui/useToast'
import { useRouter } from 'next/navigation'

export const useResetDemoData = () => {
  const router = useRouter()

  const { mutate: resetDemoData, isPending: isResetting } = useMutation({
    mutationFn: resetDemoDataAction,
    mutationKey: ['resetDemoData'],
    onSettled: (result) => {
      const isFailed = result?.error

      if (isFailed) {
        toast({ variant: 'destructive', title: 'Error resetting demo data', description: result.error })
      } else {
        toast({ variant: 'success', title: 'Data reset successfully' })
      }

      // refresh the page to fetch the new data
      window.location.reload()
    },
    onError: () =>
      toast({
        variant: 'destructive',
        title: 'Error resetting demo data',
        description: 'An error occurred while resetting the demo data.',
      }),
  })

  return {
    resetDemoData,
    isResetting,
  }
}
