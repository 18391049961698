import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './AlertDialog'
import { Loader2Icon } from 'lucide-react'

interface BaseProps {
  title: React.ReactNode
  description: React.ReactNode
  isLoading?: boolean
  classes?: {
    overlay?: string
  }

  onConfirm: () => void
}

export const ConfirmationDialog = ({
  open,
  title,
  description,
  isLoading,
  classes,
  onCancel,
  onConfirm,
}: BaseProps & { open: boolean; onCancel: () => void }) => {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent classes={{ overlay: classes?.overlay }}>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction disabled={isLoading} onClick={onConfirm}>
            {isLoading ? <Loader2Icon className="mr-2 animate-spin" size={16} /> : null}
            Yes
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export const TriggerConfirmationDialog = ({
  trigger,
  title,
  classes,
  description,
  onConfirm,
}: BaseProps & { trigger: React.ReactNode }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent classes={{ overlay: classes?.overlay }}>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Yes</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
