'use client'

import { Combobox, Form } from '@/components/ui/Form'
import { useClientContext } from '../../providers/ClientProvider/context'
import { usePathname } from 'next/navigation'
import { Option } from '@/components/ui/utils'
import { clientToOption } from '../../providers/ClientProvider/utils'
import type { ClientWithProductGroup } from '../../providers/ClientProvider/types'

const ELIGIBLE_PATHS = ['/investigation', '/claim', '/dashboard']

/**
 * Select a client for the client context
 */
export const ClientSelector = () => {
  const pathname = usePathname()
  const { clients, isLoadingClients, handleClientSelected, clientForm, selectedClient } = useClientContext()

  if (!ELIGIBLE_PATHS.some((p) => pathname.includes(p))) return null

  const handleSelect = (
    client_: Option<string, ClientWithProductGroup> | Option<string, ClientWithProductGroup>[] | null,
  ) => {
    if (!client_) return

    const client = Array.isArray(client_) ? client_[0] : client_

    handleClientSelected(client.data)
  }

  // List all client product groups,
  // additionaly prepending the regular client option if some of the client's products don't have a product group
  const options =
    clients?.flatMap((client) => {
      const hasProductGroups = client.productGroups.length

      if (hasProductGroups) {
        const eachProductHasGroup = client.product.every((product) => !!product.clientProductGroupId)
        const groupOptions = client.productGroups.map((group) => clientToOption(client, group))

        if (!eachProductHasGroup) {
          groupOptions.unshift(clientToOption(client))
        }

        return groupOptions
      }

      return clientToOption(client)
    }) ?? []

  return (
    <Form {...clientForm}>
      <Combobox
        control={clientForm.control}
        name="client"
        placeholder="Client"
        options={options}
        isLoading={isLoadingClients && !selectedClient}
        classes={{ root: 'w-44' }}
        shouldFilter
        onSelect={handleSelect}
      />
    </Form>
  )
}
