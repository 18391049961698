import { ClientProductGroupSchema } from '@core/zod/prismaZodSchema/modelSchema/ClientProductGroupSchema'
import { ClientSchema } from '@core/zod/prismaZodSchema/modelSchema/ClientSchema'
import { ClientTypeSchema } from '@core/zod/prismaZodSchema/inputTypeSchemas/ClientTypeSchema'
import { OptionSchema } from '../../../../components/ui/utils'
import { z } from 'zod'

export const ClientWithProductGroupSchema = ClientSchema.extend({
  id: z.string(),
  type: ClientTypeSchema.nullish(),
  group: ClientProductGroupSchema.nullable(),
  companyName: z.string().nullish(),
})

export type ClientWithProductGroup = z.infer<typeof ClientWithProductGroupSchema>

export const ClientFormSchema = z.object({
  client: OptionSchema(z.string(), ClientWithProductGroupSchema),
})

export type ClientForm = z.infer<typeof ClientFormSchema>
